import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Avatar from '@material-ui/core/Avatar';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import truckImage from './assets/truck.png';

import './PaymentForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.delivery_method) {
    errors.delivery_method = 'Debe elegir un método de envío';
  }
  if (!values.comments) {
    errors.comments = 'Comentario inválido';
  }
  return errors;
};

const PaymentForm = (props) => {
  const {
    deliveryMethod, handleSubmit, onSubmit, submitting, envios,
  } = props;

  return (
    <div className="container" data-testid="payment-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <ShoppingCart />
          </Avatar>
          <h4>M&eacute;todo de env&iacute;o</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            {envios.length > 0 ? envios.map(envio=>(
              <div className="checkout-radio">
                <Field
                  formGroupClassName={parseInt(deliveryMethod, 10) === envio.id ? 'radio-active' : ''}
                  name="delivery_method"
                  label=""
                  component={Input}
                  type="radio"
                  value={envio.id.toString()}
                  renderRadioDiv={() => (
                  <div className="radio-button-info">
                    <div className="radio-button-image">
                      <img src={truckImage} className="truck-image" alt="envio-CABA-lenceria" />
                    </div>
                    <div className="radio-button-text">
                      <span>{envio.name}</span>
                      <span className="radio-span-subtitle">
                          {envio.monto_minimo > 0 ? `¡ENVIO GRATIS SUPERANDO LOS $${envio.monto_minimo}!` : ""}
                        </span>
                    </div>
                  </div>
                  )}
                />
              </div>))
            :<></>}
            <Field
              component={Input}
              label="Transporte de preferencia / Comentarios"
              isTextArea
              name="comments"
            />
            <Button text="Finalizar pedido" type="submit" buttonClass="btn-block" disabled={submitting} />
          </form>
        </div>
      </div>
    </div>
  );
};

PaymentForm.defaultProps = {
  deliveryMethod: null,
};

PaymentForm.propTypes = {
  deliveryMethod: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'payment',
  validate,
})(PaymentForm);
