import * as constants from 'redux/constants/app.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  minimumPurchase: 0,
  discount: [10, 20],
  showPreloader: true,
  sliders: [],
  whatsapp: [],
  instagram: [],
  catalogs: [],
  prices: [],
  dataAnalytics: [],
  envios:[],
};

const appReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
  case constants.APP_INITIALIZE_REQUESTED:
  case constants.APP_DISCOUNT_REQUESTED:
  case userConstants.USER_LOGOUT_REQUESTED:
  case constants.APP_DATA_ANALYTICS_REQUESTED:
  case constants.APP_INSTAGRAM_REQUESTED:
    return {
      ...state,
      showPreloader: true,
    };
  case userConstants.USER_PROFILE_SUCCEEDED:
  case userConstants.USER_PROFILE_FAILED:
  case userConstants.USER_LOGOUT_SUCCEEDED:
  case userConstants.USER_LOGOUT_FAILED:
    return {
      ...state,
      showPreloader: false,
    };
  case constants.APP_DATA_ANALYTICS_SUCCEEDED:
    return {
      ...state,
      dataAnalytics: data.item,
    };
  case constants.APP_MINIMUM_PURCHASE_SUCCEEDED:
    return {
      ...state,
      minimumPurchase: data.item ? parseFloat(data.item.number, 0) : defaultState.minimumPurchase,
    };
  case constants.APP_SLIDERS_GET_ALL_SUCCEEDED:
    return {
      ...state,
      sliders: data.items,
    };
  case constants.APP_WHATSAPP_SUCCEEDED:
    return {
      ...state,
      whatsapp: data.item,
    };
  case constants.APP_INSTAGRAM_SUCCEEDED:
    return {
      ...state,
      instagram: data.item,
    };
  case constants.APP_CATALOGS_SUCCEEDED:
    return {
      ...state,
      catalogs: data.item,
    };
  case constants.APP_PRICES_SUCCEEDED:
    return {
      ...state,
      prices: data.item,
    };
  case constants.APP_ENVIOS_SUCCEEDED:
    return {
      ...state,
      envios: data.item,
    };
  case constants.APP_DATA_ANALYTICS_FAILED:
  case constants.APP_CATALOGS_FAILED:
  case constants.APP_ENVIOS_FAILED:
  case constants.APP_PRICES_FAILED:
  case constants.APP_SLIDERS_GET_ALL_FAILED:
  case constants.APP_WHATSAPP_FAILED:
  case constants.APP_INSTAGRAM_FAILED:
  default:
    return state;
  }
};

export default appReducer;
