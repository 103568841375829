/* eslint-disable react/prop-types */
import React, { memo, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

const InjectScript = memo(({ script }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current === null) return;
    // create a contextual fragment that will execute the script
    // beware of security concerns!!
    const doc = document
      .createRange()
      .createContextualFragment(script);
    // clear the div HTML, and append the doc fragment with the script
    divRef.current.innerHTML = '';
    divRef.current.appendChild(doc);
  });

  return <div ref={divRef} />;
});

const ScriptContainer = (props) => {
  const { scripts } = props;

  return scripts.map((script) => <InjectScript key={script.id} script={script.text} />);
};

const mapStateToProps = (state) => ({
  scripts: state.app.dataAnalytics,
});

export default connect(mapStateToProps)(ScriptContainer);
