import React from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';
import { getLocalStorage } from 'helpers/storage';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/icons/List';

import './CartSummary.scss';

const CartSummary = (props) => {
  const {
    items, shippingText, subtotal, coupon, subTotEnv,
  } = props;

  const discount = getLocalStorage('discount');
  const subtotalValue = parseFloat(subtotal);
  const percentageOrAmount = coupon?.percentage
    ? (coupon.percentage / 100) * subtotal
    : (coupon?.amount || 0);
  const totalValueWithDiscount = (discount - percentageOrAmount) > 0
    ? discount - percentageOrAmount
    : 0;
  const totalValueWithoutDiscount = (subtotal - percentageOrAmount) > 0
    ? subtotal - percentageOrAmount
    : 0;
  const totalValueWithPercetage = subtotal - percentageOrAmount;

  const findProductWholesale = (id, item) => {
    const product = item.product_wholesaler.find((prod) => prod.id === id);
    return product.price * product.quantity;
  };

  const findIDProductWholesale = (subItem, item) => {
    const values = Object.values(subItem);
    const firstArray = values[0];
    const firstObject = firstArray[0];
    const { id } = firstObject.wholesale;
    if (id > 0) {
      const product = item.product_wholesaler.find((prod) => prod.id === id);
      return `Precio por ${firstObject.wholesale.quantity} - $${product.price * product.quantity}`;
    }
    return `Precio Mayorista - $${item.price}`;
  };

  const renderDescriptions = (descriptions, colors, item, descriptionId) => {
    const color = colors.find(
      ({ stock_description_id }) => stock_description_id === Number(descriptionId),
    );
    return !!descriptions.length && (
      <li key={`${item.id}-${descriptionId}`}>
        <strong style={{ color: color.stock_descriptions.rgb }}>
          {color.stock_descriptions.name}
        </strong>
        {' - '}
        {descriptions.map(({ description, quantity: amount }, index) => (
          <span className="cart-item-description" key={`${item.id}-${description.id}`}>
            {description.id === color.id ? amount : `${description.stock_descriptions.name}: ${amount}${index === descriptions.length - 1 ? '' : ', '}`}
          </span>
        ))}
      </li>
    );
  };

  return (
    <div className="container" data-testid="cart-summary-component">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <List />
          </Avatar>
          <h4>Resumen de la compra</h4>
          <div className="summary-items">
            {items.map(({
              item, quantity, colors, subItems, subItemsDescriptions,
            }) => !!item && (
              <div key={item.id}>
                <div className="d-flex flex-row">
                  <div className="summary-item-image">
                    <img src={item.image1} alt={item.name} />
                  </div>
                  <div className="summary-item-info">
                    <ul>
                      <li className="d-flex flex-row">
                        <span className="summary-item-title">
                          {item.name}
                        </span>
                      </li>
                      <li>
                        <span className="summary-item-description">
                          {item.description}
                        </span>
                      </li>
                      {colors && (
                        <div className="descriptions-container-cart-summary">
                          {Object.values(subItemsDescriptions).some((value) => typeof value === 'object' && Object.keys(value).length > 0) && (
                            <>
                              {Object.values(subItemsDescriptions).map((subItemDescription) => (
                                <>
                                  {Object.keys(subItemDescription).length > 0 && (
                                    <div className="precios-container">
                                      <span className="title-description search-item">{findIDProductWholesale(subItemDescription, item)}</span>
                                      {Object.entries(subItemDescription).map(
                                        ([descriptionId, descriptionArray]) => renderDescriptions(
                                          descriptionArray, colors, item, descriptionId,
                                        ),
                                      )}
                                    </div>
                                  )}
                                </>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                      {subItems?.length > 0 && (
                        <>
                          {subItems.map((subItem) => (
                            <>
                              <div className="precios-container">
                                <span className="title-description search-item">
                                  {subItem.wholesale.id > 0 ? `Precio por ${subItem.wholesale.quantity} -` : 'Precio Mayorista -'}
                                  {' '}
                                </span>
                                <span className="summary-item-price">
                                  {`$${(subItem.wholesale.id > 0 ? (findProductWholesale(subItem.wholesale.id, item)) : item.price).toLocaleString('de-DE')}`}
                                </span>
                              </div>
                              <li>
                                <span className="summary-item-title">
                                  {`Cantidad: ${subItem.quantity}`}
                                </span>
                              </li>
                            </>
                          ))}
                        </>
                      )}
                      {!(item.product_wholesaler.length > 0)
                        && (item.stock_product_descriptions.length === 0) && (
                        <>
                          <div className="precios-container">
                            <span className="title-description search-item">
                              Precio mayorista -
                            </span>
                            <span className="summary-item-price">
                              {(item.product_wholesaler.length === 0) && (`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`)}
                              {!!item.sale && (item.product_wholesaler.length === 0) && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                              {(item.product_wholesaler.length > 0) && (`$${item.price.toLocaleString('de-DE')}`)}
                            </span>
                          </div>
                          <li>
                            <span className="summary-item-title">
                              {`Cantidad: ${quantity}`}
                            </span>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="separator" />
              </div>
            ))}
          </div>
          <ul className="summary-subtotal">
            <li>
              Subtotal:
              {discount === null
                ? (
                  <span className="float-right">
                    {`$${subtotalValue.toFixed(2)}`}
                  </span>
                )
                : (
                  <del className="float-right">
                    {`$${subtotalValue.toFixed(2)}`}
                  </del>
                )}
            </li>
            <li>
              Descuentos
              <span className="float-right">
                {
                  !coupon ? (
                    discount != null
                    && (
                      <>
                        {getLocalStorage('percentage')}
                        %
                      </>
                    )
                  ) : (
                    <>
                      {discount != null ? (`CUPÓN ${coupon.percentage ? (`${coupon.percentage}%`) : (`$${coupon.amount} y ${getLocalStorage('percentage')}%:`)} `) : (`CUPÓN ${coupon.percentage ? (`${coupon.percentage}%`) : (`$${coupon.amount}`)} `)}
                    </>
                  )
                }
              </span>
            </li>
            <li>
              Costo de envío:
              <span className="float-right">
                {shippingText}
              </span>
            </li>
            <div className="separator" />
            <li>
              Total:
              {
                discount === null
                  ? (
                    <span className="float-right">
                      {`$${(totalValueWithoutDiscount + subTotEnv).toFixed(2) }`}
                    </span>
                  )
                  : (
                    <span className="float-right">
                      {
                         coupon?.percentage ? (
                          `$${(totalValueWithPercetage + subTotEnv).toFixed(2) }`
                         ) : (
                          `$${(totalValueWithDiscount + subTotEnv).toFixed(2) }`
                         )
                      }
                    </span>
                  )
              }
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

CartSummary.defaultProps = {
  coupon: null,
};

CartSummary.propTypes = {
  items: cartItemsArrayPropTypes.isRequired,
  shippingText: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
  }),
};

export default CartSummary;
