import * as constants from 'redux/constants/favourite.constants';

const defaultState = {
  items: [],
  loading: false,
  notification: '',
};

const favouriteReducer = (state = defaultState, action) => {
  switch (action.type) {
  case constants.FAVOURITE_UPDATE_REQUESTED:
    return {
      ...state,
      notification: action.notification,
    };
  case constants.FAVOURITE_UPDATE_SUCCEEDED:
    return {
      ...state,
      items: action.items,
    };
  default:
    return state;
  }
};

export default favouriteReducer;
