import * as userConstants from 'redux/constants/user.constants';

const downloadFile = (data, orderId) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `Pedido${orderId}.pdf`);
  document.body.appendChild(link);
  link.click();
};

const fileMiddleware = () => (next) => (action) => {
  switch (action.type) {
  case userConstants.USER_GET_ORDER_PDF_SUCCEEDED:
    downloadFile(action.data, action.orderId);
    break;
  default:
    break;
  }
  return next(action);
};

export default fileMiddleware;
