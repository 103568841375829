import * as constants from 'redux/constants/product.constants';

export const getSearch = (value) => ({
  type: constants.PRODUCT_GET_SEARCH_REQUESTED,
  value,
});

export const getSingle = (value) => ({
  type: constants.PRODUCT_GET_SINGLE_REQUESTED,
  value,
});
