import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import { cartItemsArrayPropTypes, userPropTypes } from 'helpers/propTypes';
import { isAbleToPurchase } from 'helpers/cart';
import { CART_CHECKOUT_DELIVERY_REQUESTED } from 'redux/constants/cart.constants';
import {
  checkout, submitCoupon, deleteCoupon, getProducts,
} from 'redux/actions/cart.actions';
import {
  cartItemIdsSelector, cartItemsSelector, cartSizeSelector, cartSubtotalSelector,
} from 'redux/selectors/cart.selector';
import { getShippingText } from 'components/forms/PaymentForm/constants';

import Checkout from 'views/Checkout';
import DeliveryForm from 'components/forms/DeliveryForm';
import PaymentForm from 'components/forms/PaymentForm';

const DeliveryContainer = (props) => {
  const {
    cartItems, cartSize, checkoutAction, submitCouponAction, deliveryMethod, items, loading,
    minimumPurchase, subtotal, user, coupon, deleteCouponAction, resetCouponField,
    getProductsAction, ids, envios,
  } = props;
  const [deliveryFormData, setDeliveryFormData] = useState(null);
  const [couponText, setCouponText] = useState(null);
  const [emailComplete, setEmailComplete] = useState(false);
  const [emailCoupon, setEmailCoupon] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const envio = envios.find(envio => envio.id.toString() === deliveryMethod);
  const subTotEnv = deliveryMethod ? envio.monto_minimo !== 0 && subtotal >= envio.monto_minimo ? 0: envio.monto : 0;

  useEffect(() => {
    if (coupon) deleteCouponAction();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ids.length > 0 && !initialized) {
      getProductsAction(ids);
      setInitialized(true);
    }
    return () => getProductsAction([]);
    // eslint-disable-next-line
  }, [getProductsAction, JSON.stringify(ids)]);

  const onSubmitDeliveryForm = (formData) => {
    if (isAbleToPurchase(subtotal, minimumPurchase)) {
      const data = { ...formData, coupon: coupon?.id };
      setDeliveryFormData(data);
    }
  };

  const onSubmitCoupon = () => {
    if (couponText && emailCoupon && subtotal) {
      submitCouponAction(subtotal, couponText, emailCoupon);
    }
  };

  const onDeleteCoupon = () => {
    deleteCouponAction();
    resetCouponField('');
    setCouponText('');
  };

  const validationEmailCoupon = (email) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailComplete(false);
      onDeleteCoupon();
    } else {
      setEmailComplete(true);
      setEmailCoupon(email);
    }
  };

  const renderDeliveryForm = () => (
    <DeliveryForm
      coupon={coupon}
      onSubmit={onSubmitDeliveryForm}
      user={user}
      onSubmitCoupon={coupon ? onDeleteCoupon : onSubmitCoupon}
      couponText={couponText}
      setCouponText={setCouponText}
      emailComplete={emailComplete}
      setEmailComplete={setEmailComplete}
      setEmailCoupon={setEmailCoupon}
      validationEmailCoupon={validationEmailCoupon}
    />
  );

  const onSubmitPaymentForm = (formData) => {
    if (isAbleToPurchase(subtotal, minimumPurchase)) {
      checkoutAction(
        CART_CHECKOUT_DELIVERY_REQUESTED,
        { ...deliveryFormData, ...formData },
        cartItems,
        user ? user.id : null,
      );
    }
  };

  const renderPaymentForm = () => (
    <PaymentForm
      deliveryMethod={deliveryMethod}
      onSubmit={onSubmitPaymentForm}
      envios={envios}
    />
  );

  return (
    <Checkout
      coupon={coupon}
      cartSize={cartSize}
      items={items}
      loading={loading}
      minimumPurchase={minimumPurchase}
      renderForm={deliveryFormData ? renderPaymentForm : renderDeliveryForm}
      shippingText={getShippingText(deliveryMethod, subtotal, envios)}
      subtotal={subtotal}
      subTotEnv={subTotEnv}
      url="/envio-a-domicilio"
      urlText="Envío a domicilio"
    />
  );
};

const selector = formValueSelector('payment');
const mapStateToProps = (state) => ({
  coupon: state.cart.coupon,
  cartItems: state.cart.items,
  cartSize: cartSizeSelector(state),
  deliveryMethod: selector(state, 'delivery_method'),
  ids: cartItemIdsSelector(state),
  items: cartItemsSelector(state),
  loading: state.cart.loading,
  minimumPurchase: state.app.minimumPurchase,
  subtotal: cartSubtotalSelector(state),
  user: state.user.data,
  envios: state.app.envios,
});

const mapDispatchToProps = (dispatch) => ({
  checkoutAction: bindActionCreators(checkout, dispatch),
  submitCouponAction: bindActionCreators(submitCoupon, dispatch),
  deleteCouponAction: bindActionCreators(deleteCoupon, dispatch),
  resetCouponField: (value) => dispatch(change('delivery', 'coupon', value)),
  getProductsAction: bindActionCreators(getProducts, dispatch),
});

DeliveryContainer.defaultProps = {
  deliveryMethod: null,
  user: null,
  coupon: null,
};

DeliveryContainer.propTypes = {
  cartItems: cartItemsArrayPropTypes.isRequired,
  cartSize: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  getProductsAction: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  submitCouponAction: PropTypes.func.isRequired,
  deleteCouponAction: PropTypes.func.isRequired,
  resetCouponField: PropTypes.func.isRequired,
  checkoutAction: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string,
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  user: userPropTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryContainer);
