/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { categoriesArrayPropTypes } from 'helpers/propTypes';

import useWindowSize from 'components/hooks/useWindowSize';
import Logo from 'components/main/Header/Logo';

import './Menu.scss';

const Menu = (props) => {
  const {
    categories, closeMenu, isMobile, isUserLoggedIn, ulClassName,
  } = props;
  const size = useWindowSize();

  const renderProductsMobile = () => (
    <div className="products-menu-mobile" data-testid="nav-products-mobile">
      <div>
        <NavLink to="/productos" activeClassName="active" onClick={closeMenu} data-testid="nav-products">
          Categorías
        </NavLink>
        <label htmlFor="products-menu-mobile-checkbox-0" className="products-menu-mobile-toggle">
          <i className="fa fa-angle-down" />
        </label>
      </div>
      <input type="checkbox" id="products-menu-mobile-checkbox-0" />
      <ul className="products-sub-menu-mobile">
        {categories.map((category) => (
          <li key={`category-${category.id}-mobile`}>
            <NavLink activeClassName="active" onClick={closeMenu} to={`/productos/${category.code}`}>
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderOffersMobile = () => (
    <div className="products-menu-mobile" data-testid="nav-offers-mobile">
      <div>
        <NavLink to="/ofertas" activeClassName="active" onClick={closeMenu} data-testid="nav-offers">
          Ofertas
        </NavLink>
        <label htmlFor="offers-menu-mobile-checkbox-0" className="products-menu-mobile-toggle">
          <i className="fa fa-angle-down" />
        </label>
      </div>
      <input type="checkbox" id="offers-menu-mobile-checkbox-0" />
      <ul className="products-sub-menu-mobile">
        {categories.map((category) => (
          <li key={category.id}>
            <NavLink activeClassName="active" onClick={closeMenu} to={`/ofertas/${category.code}`}>
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <ul className={ulClassName} data-testid="menu">
      {size.width > 991 && (
        <li role="presentation">
          <ul className="navbar-menu-icons">
            <li>
              <a href="tel:5491157482560" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-phone" />
              </a>
            </li>
            <li>
              <a href="mailto:lenmaguen@gmail.com" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-envelope-o" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/lenceriamaguen" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/lenceriamaguen?igshid=MmJiY2I4NDBkZg==" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
        </li>
      )}
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/home" activeClassName="active" data-testid="nav-home">
          Home
        </NavLink>
      </li>
      <li className="products-menu-link">
        {!isMobile && (
          <NavLink to="/productos" activeClassName="active" onClick={closeMenu} data-testid="nav-products">
            Categorías
          </NavLink>
        )}
        {isMobile && renderProductsMobile()}
      </li>
      <li className="products-menu-link">
        {!isMobile && (
          <NavLink to="/ofertas" activeClassName="active" onClick={closeMenu} data-testid="nav-offers">
            Ofertas
          </NavLink>
        )}
        {isMobile && renderOffersMobile()}
      </li>
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/preguntas-frecuentes" activeClassName="active" data-testid="nav-faq">
          Preguntas frecuentes
        </NavLink>
      </li>
      {size.width > 991 && <li role="presentation" className="navbar-logo"><Logo className="logo" /></li>}
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/precios" activeClassName="active" data-testid="nav-faq">
          Lista de precios
        </NavLink>
      </li>
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/catalogos" activeClassName="active" data-testid="nav-faq">
          Catálogo
        </NavLink>
      </li>
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/contacto" activeClassName="active" data-testid="nav-contact">
          Contacto
        </NavLink>
      </li>
      {isUserLoggedIn && (
        <li role="presentation" onClick={closeMenu}>
          <NavLink to="/compras" activeClassName="active" data-testid="nav-purchases">
            Mis compras
          </NavLink>
        </li>
      )}
    </ul>
  );
};

Menu.defaultProps = {
  isMobile: false,
};

Menu.propTypes = {
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool.isRequired,
  ulClassName: PropTypes.string.isRequired,
};

export default Menu;
