import React from 'react';
import { connect } from 'react-redux';

import { whatsappPropTypes } from 'helpers/propTypes';

import whatsappImage from './assets/whatsapp.png';

import './Whatsapp.scss';

const Whatsapp = (props) => {
  const { whatsapp } = props;
  return whatsapp && (
    <div className="whatsapp">
      <div className="dropdown">
        <img src={whatsappImage} className="whatsapp-image" alt="whatsapp" />
        <div className="dropdown-content">
          <ul
            style={{
              listStyle: 'none', padding: 0, display: 'grid',
            }}
          >
            <li style={{ backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center' }}>
              <span>Whatsapp estamos online!</span>
            </li>
            {whatsapp.map((numero) => (
              <li key={`numero-${numero.id}`}>
                <div className="dropdown-content-productos">
                  <img src={numero.image} alt={numero.name} className="whatsapp-photo" />
                  <a
                    href={`https://wa.me/${numero.number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="whatsapp"
                  >
                    {numero.name}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  whatsapp: state.app.whatsapp,
});

Whatsapp.defaultProps = {
  whatsapp: null,
};

Whatsapp.propTypes = {
  whatsapp: whatsappPropTypes,
};

export default connect(mapStateToProps)(Whatsapp);
