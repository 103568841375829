import { get } from 'services/api';

export const getAll = async () => {
  const response = await get('products');
  return response;
};

export const getIds = async (value) => {
  const response = await get(`products/getByIds/${value.join(',')}`);
  return response;
};

export const getMaxPrice = async () => {
  const response = await get('products/maxPrice');
  return response;
};

export const getMostSelled = async () => {
  const response = await get('products/mostSelled');
  return response;
};

export const getNewArrivals = async () => {
  const response = await get('products/newArrivals');
  return response;
};

export const getOffers = async () => {
  const response = await get('products/offers');
  return response;
};

export const getSearch = async (value) => {
  const response = await get(`products/getBySearch/${encodeURIComponent(value)}`);
  return response;
};

export const getSingle = async (value) => {
  const response = await get(`products/getByCode/${encodeURIComponent(value)}`);
  return response;
};
