import { removeLocalStorage } from 'helpers/storage';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/cart.constants';
import { delivery, pickup, submitCoupon } from 'services/cart.services';
import { getIds } from 'services/product.services';

export function* cartCheckoutDelivery(action) {
  try {
    const data = yield call(delivery, action);
    yield put({ type: constants.CART_CHECKOUT_SUCCEEDED, data });
    removeLocalStorage('descriptionsCart');
  } catch (error) {
    yield put({ type: constants.CART_CHECKOUT_FAILED, error });
  }
}

export function* cartCheckoutPickup(action) {
  try {
    const data = yield call(pickup, action);
    yield put({ type: constants.CART_CHECKOUT_SUCCEEDED, data });
    removeLocalStorage('descriptionsCart');
  } catch (error) {
    yield put({ type: constants.CART_CHECKOUT_FAILED, error });
  }
}

export function* cartGetProducts(action) {
  try {
    if (action.value.length > 0) {
      const data = yield call(getIds, action.value);
      yield put({ type: constants.CART_GET_PRODUCTS_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.CART_GET_PRODUCTS_FAILED, error });
  }
}

export function* cartSubmitCoupon(action) {
  try {
    const data = yield call(submitCoupon, action.total, action.coupon, action.email);
    yield put({ type: constants.CART_COUPON_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CART_COUPON_FAILED, error });
  }
}

export function* watchCart() {
  yield all([
    takeLatest(constants.CART_CHECKOUT_DELIVERY_REQUESTED, cartCheckoutDelivery),
    takeLatest(constants.CART_CHECKOUT_PICKUP_REQUESTED, cartCheckoutPickup),
    takeLatest(constants.CART_GET_PRODUCTS_REQUESTED, cartGetProducts),
    takeLatest(constants.CART_COUPON_REQUESTED, cartSubmitCoupon),
  ]);
}
