import { getLocalStorage } from 'helpers/storage';
import { post } from 'services/api';

export const delivery = async (values) => {
  const data = {
    ...values,
    colors: getLocalStorage('descriptionsCart') ?? [],
  };
  const response = await post('checkout/delivery', data);
  return response;
};

export const pickup = async (values) => {
  const data = {
    ...values,
    colors: getLocalStorage('descriptionsCart') ?? [],
  };
  const response = await post('checkout/pickup', data);
  return response;
};

export const submitCoupon = async (total, coupon, email) => {
  const response = await post('searchCoupon', { total, coupon, email });
  return response;
};
