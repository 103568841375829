
export const DELIVERY_FREE = 'DELIVERY_FREE';
export const DELIVERY_PAY = 'DELIVERY_PAY';
export const DELIVERY_FREE_PAY = 'DELIVERY_FREE_PAY';

export const DELIVERY_FREE_MESSAGE = 'Gratis';
export const DELIVERY_PAY_MESSAGE = 'A abonar contra entrega';
export const NO_DELIVERY_METHOD_MESSAGE = 'No seleccionó un método de envío';

export const getShippingText = (deliveryMethod, subtotal, envios) => {
  const envio = envios.find(envio => envio.id.toString() === deliveryMethod);
  console.log(envio);
  if(!deliveryMethod){
    return 'No seleccionó un método de envío'
  }
  if(envio.monto_minimo && subtotal > envio.monto_minimo){
    return 'Envio Gratis';
  }else{
    return '$' + envio.monto;
  }
};
