import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { cartItemsArrayPropTypes, userPropTypes } from 'helpers/propTypes';
import { isAbleToPurchase } from 'helpers/cart';
import { CART_CHECKOUT_PICKUP_REQUESTED } from 'redux/constants/cart.constants';
import {
  checkout, submitCoupon, deleteCoupon, getProducts,
} from 'redux/actions/cart.actions';
import {
  cartItemIdsSelector, cartItemsSelector, cartSizeSelector, cartSubtotalSelector,
} from 'redux/selectors/cart.selector';

import Checkout from 'views/Checkout';
import PickupForm from 'components/forms/PickupForm';

const PickupContainer = (props) => {
  const {
    cartItems, cartSize, checkoutAction, submitCouponAction, items, loading, minimumPurchase,
    subtotal, user, coupon, deleteCouponAction, resetCouponField, getProductsAction, ids,
  } = props;
  const [couponText, setCouponText] = useState(null);
  const [emailComplete, setEmailComplete] = useState(false);
  const [emailCoupon, setEmailCoupon] = useState(null);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (coupon) deleteCouponAction();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ids.length > 0 && !initialized) {
      getProductsAction(ids);
      setInitialized(true);
    }
    return () => getProductsAction([]);
    // eslint-disable-next-line
  }, [getProductsAction, JSON.stringify(ids)]);

  const onDeleteCoupon = () => {
    deleteCouponAction();
    resetCouponField('');
    setCouponText('');
  };

  const validationEmailCoupon = (email) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailComplete(false);
      onDeleteCoupon();
    } else {
      setEmailComplete(true);
      setEmailCoupon(email);
    }
  };

  const onSubmitPickUpForm = (formData) => {
    if (isAbleToPurchase(subtotal, minimumPurchase)) {
      const data = { ...formData, coupon: coupon?.id };
      checkoutAction(
        CART_CHECKOUT_PICKUP_REQUESTED,
        data,
        cartItems,
        user ? user.id : null,
      );
      deleteCouponAction();
    }
  };

  const onSubmitCoupon = () => {
    if (couponText && emailCoupon && subtotal) {
      submitCouponAction(subtotal, couponText, emailCoupon);
    }
  };

  const renderPickUpForm = () => (
    <PickupForm
      coupon={coupon}
      onSubmit={onSubmitPickUpForm}
      onSubmitCoupon={coupon ? onDeleteCoupon : onSubmitCoupon}
      couponText={couponText}
      setCouponText={setCouponText}
      emailComplete={emailComplete}
      setEmailComplete={setEmailComplete}
      setEmailCoupon={setEmailCoupon}
      validationEmailCoupon={validationEmailCoupon}
      user={user}
    />
  );

  return (
    <Checkout
      coupon={coupon}
      cartSize={cartSize}
      items={items}
      loading={loading}
      minimumPurchase={minimumPurchase}
      renderForm={renderPickUpForm}
      shippingText="Gratis"
      subtotal={subtotal}
      url="/retiro-en-sucursal"
      urlText="Retiro en Sucursal Lenceria Maguen"
    />
  );
};

const mapStateToProps = (state) => ({
  coupon: state.cart.coupon,
  cartItems: state.cart.items,
  cartSize: cartSizeSelector(state),
  ids: cartItemIdsSelector(state),
  items: cartItemsSelector(state),
  loading: state.cart.loading,
  minimumPurchase: state.app.minimumPurchase,
  subtotal: cartSubtotalSelector(state),
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  checkoutAction: bindActionCreators(checkout, dispatch),
  submitCouponAction: bindActionCreators(submitCoupon, dispatch),
  deleteCouponAction: bindActionCreators(deleteCoupon, dispatch),
  resetCouponField: (value) => dispatch(change('pickup', 'coupon', value)),
  getProductsAction: bindActionCreators(getProducts, dispatch),
});

PickupContainer.defaultProps = {
  user: null,
  coupon: null,
};

PickupContainer.propTypes = {
  cartItems: cartItemsArrayPropTypes.isRequired,
  cartSize: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  checkoutAction: PropTypes.func.isRequired,
  getProductsAction: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  submitCouponAction: PropTypes.func.isRequired,
  deleteCouponAction: PropTypes.func.isRequired,
  resetCouponField: PropTypes.func.isRequired,
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  user: userPropTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupContainer);
