import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { sendErrorNotification } from 'helpers/notifications';

import './QuantitySelector.scss';

const QuantitySelector = (props) => {
  const {
    description, limit, onQuantityChange, quantity, quantityOthersWholesale,
  } = props;
  const inputRef = useRef(null);
  const [value, setValue] = useState(quantity);
  const sumQuantityOtherWholesaleInt = quantityOthersWholesale.reduce(
    (partialSum, a) => partialSum + a, 0,
  );

  useEffect(() => {
    setValue(quantity);
    const handleKeyUp = (event) => {
      // 13: Enter key
      if (event.keyCode === 13) {
        inputRef.current.blur();
      }
    };
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
    // eslint-disable-next-line
  }, [quantity]);

  const onChange = (event) => {
    /* const input = (wholesale && event.target.value < 12 && event.target.value > 0)
      ? 12 : event.target.value; */
    setValue(event.target.value);
  };

  const onFocus = () => {
    if (value === 0) setValue('');
  };

  const onSubmit = () => {
    if (!value || !/^[0-9]*$/i.test(value)) {
      setValue(quantity);
      return;
    }

    const valueInt = parseInt(value, 0);
    const suma = valueInt + sumQuantityOtherWholesaleInt;
    if (suma > limit) {
      const resta = parseInt(limit, 0) - sumQuantityOtherWholesaleInt;
      sendErrorNotification(`El producto no cuenta con más de ${limit} unidades para agregar al carrito`);
      setValue(resta);
      onQuantityChange(resta, description);
      return;
    }

    setValue(valueInt);
    onQuantityChange(valueInt, description);
  };

  const onClickQuantity = (valor) => {
    const suma = valor + sumQuantityOtherWholesaleInt;
    if (valor < 0) {
      return;
    }

    if (suma > limit) {
      sendErrorNotification(`El producto no cuenta con más de ${limit} unidades para agregar al carrito`);
    } else {
      setValue(valor);
      onQuantityChange(valor, description);
    }
  };

  return (
    <div className="quantity d-flex flex-row align-items-center" data-testid="quantity-selector">
      {!description ? (
        <>
          <span className="quantity-text">Cantidad:</span>
          <div className="quantity-selector">
            <span className="minus" role="presentation" onClick={() => onClickQuantity(quantity - 1)} data-testid="quantity-selector-minus">
              <i className="fa fa-minus" aria-hidden="true" />
            </span>
            <span>
              <input type="number" value={value} onChange={onChange} onBlur={onSubmit} onFocus={onFocus} ref={inputRef} min={0} max={limit} data-testid="quantity-selector-input" />
            </span>
            <span className="plus" role="presentation" onClick={() => onClickQuantity(quantity + 1)} data-testid="quantity-selector-plus">
              <i className="fa fa-plus" aria-hidden="true" />
            </span>
          </div>
        </>
      ) : (
        <div className="quantity-selector-description">
          <span> 
            <span className="plus" role="presentation" onClick={() => onClickQuantity(quantity + 1)} data-testid="quantity-selector-plus">
              <i className="fa fa-plus" aria-hidden="true" />
            </span>
            <span>
              <input
                type="number"
                value={value}
                onChange={onChange}
                onBlur={onSubmit}
                onFocus={onFocus}
                ref={inputRef}
                min={0}
                max={limit}
                data-testid="quantity-selector-input"
              />
            </span>
            <span className="minus" role="presentation" onClick={() => onClickQuantity(quantity - 1)} data-testid="quantity-selector-minus">
              <i className="fa fa-minus" aria-hidden="true" />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

QuantitySelector.defaultProps = {
  description: null,
  quantityOthersWholesale: [0],
};

QuantitySelector.propTypes = {
  description: PropTypes.shape(),
  limit: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  quantityOthersWholesale: PropTypes.arrayOf(PropTypes.number),
};

export default QuantitySelector;
