import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'components/commons/Breadcrumb';
import logoImage from "./assets/logo.png"

import './Catalogue.scss';

const Catalogue = (props) => {
  const { catalogs } = props;
  const [catalogsState, setCatalogsState] = useState([]);

  useEffect(() => {
    if (catalogs.length > 0) {
      const initialCatalogsState = catalogs.map((catalog, index) => ({
        ...catalog,
        clicked: index === 0,
      }));
      setCatalogsState(initialCatalogsState);
    }
  }, [catalogs]);

  const handleLinkClick = (index) => {
    const updatedCatalogs = catalogsState.map((catalog, i) => ({
      ...catalog,
      clicked: i === index,
    }));
    setCatalogsState(updatedCatalogs);
  };

  return (
    <div className="container fix-space" data-testid="faq-page">
      <div className="row">
        <div className="col clearfix">
          <Breadcrumb urls={['/catalogos']} names={['Catalogos']} />
        </div>
      </div>
      <div className="catalogue-grid">
        {catalogs.length > 0 ? (
          catalogsState.map((catalog, index) => (
            <div className="catalogue-item" key={`catalog-${catalog.id}`}>
              <a href={catalog.pdf} target="_blank" rel="noopener noreferrer" onClick={() => handleLinkClick(index)}>
                <img src={catalog.image ?? logoImage} alt="Catalog" className="catalogue-image" />
                <div className="catalogue-title">{catalog.title}</div>
              </a>
            </div>
          ))
        ) : (
          <h2>No hay catálogos disponibles</h2>
        )}
      </div>
    </div>
  );
};

Catalogue.propTypes = {
  catalogs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pdf: PropTypes.string,
  })).isRequired,
};

export default Catalogue;
