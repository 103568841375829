import * as userConstants from 'redux/constants/user.constants';
import * as cartConstants from 'redux/constants/cart.constants';
import { sendErrorNotification, sendSuccessNotification } from 'helpers/notifications';

const notificationMiddleware = () => (next) => (action) => {
  const { data, error, type } = action;
  switch (type) {
  case userConstants.USER_LOGIN_FAILED:
  case userConstants.USER_REGISTER_FAILED:
  case userConstants.USER_RESET_PASSWORD_FAILED:
  case userConstants.USER_CONTACT_FAILED:
  case cartConstants.CART_CHECKOUT_FAILED:
  case userConstants.USER_UPDATE_PROFILE_FAILED:
  case userConstants.USER_CHANGE_PASSWORD_FAILED:
  case cartConstants.CART_COUPON_FAILED:
  case userConstants.USER_VERIFY_EMAIL_FAILED:
  case userConstants.USER_CHANGE_PASSWORD_EMAIL_FAILED:
  case userConstants.USER_NEWSLETTER_FAILED:
    sendErrorNotification(error.message);
    break;
  case userConstants.USER_REGISTER_SUCCEEDED:
  case userConstants.USER_RESET_PASSWORD_SUCCEEDED:
  case userConstants.USER_CONTACT_SUCCEEDED:
  case userConstants.USER_UPDATE_PROFILE_SUCCEEDED:
  case userConstants.USER_CHANGE_PASSWORD_SUCCEEDED:
  case userConstants.USER_NEWSLETTER_SUCCEEDED:
  case userConstants.USER_VERIFY_EMAIL_SUCCEEDED:
  case userConstants.USER_CHANGE_PASSWORD_EMAIL_SUCCEEDED:
    sendSuccessNotification(data.message);
    break;
  default:
    break;
  }
  return next(action);
};

export default notificationMiddleware;
